import { Activity, sendActivityData } from './api';
import { createActivity } from './utils/activity';
import { batchSend } from './utils/batchSend';

const sendData = batchSend(sendActivityData);

/**
 * Get the current list of warnings, then reset the list until next time.
 * @returns list of `Activity` created from current list of warnings
 */
const flushMigrateWarningActivities = (): Activity[] => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-assignment
    const _migrateWarnings: string[] = (window as any).jQuery.migrateWarnings;
    // need to make a copy, reset is destructive
    const migrateWarnings = _migrateWarnings.map((warning) => {
        // using the warning itself as the event name
        // so we can better aggregate warnings about the same api
        return createActivity(warning, 0);
    });
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    (window as any).jQuery.migrateReset();
    return migrateWarnings;
};

/**
 * Jquery migrate tracks all deprecated api usage and collects them
 * We want to send them to our telemetry for tracking.
 */
export const initializeJqMigrateTelemetry = () => {
    const sendMigrateWarnings = () => {
        flushMigrateWarningActivities().forEach((activity) => {
            sendData(activity);
        });
    };

    sendMigrateWarnings();

    window.setInterval(sendMigrateWarnings, 3000);

    window.addEventListener('pagehide', () => {
        const activities = flushMigrateWarningActivities();
        if (activities.length) {
            void sendActivityData(activities);
        }
    });
};
