import superagent, { ResponseError, SuperAgentRequest } from 'superagent';
import { getGenericMessage, isResponseError } from './error';

export type Method = 'get' | 'post' | 'put';

export type ApiRequester = <TData>(url: string, sendParams?: string | object | undefined) => Promise<TData>;

/**
 * getPost means stuff that are semantically `Get` requests but we do them as `Post`
 */
export type ApiRequesterClient = Record<Method | 'getPost', ApiRequester>;

const buildFullUrl = (url: string) => {
    return `/higherlogic/ocapi/oc${url}`;
};

export const getRequestVerificationToken = () => {
    const formTokenElement = document.getElementsByName('__HL-RequestVerificationToken')[0] as HTMLInputElement;
    const formToken = formTokenElement ? formTokenElement.value : '';
    return formToken;
};

const buildOcApiRequester = (superAgentCallback: (url: string) => SuperAgentRequest, method: Method): ApiRequester => {
    return async <TData>(url: string, sendParams?: string | object | undefined): Promise<TData> => {
        try {
            const fullUrl = buildFullUrl(url);
            const formToken = getRequestVerificationToken();

            const request = superAgentCallback(fullUrl)
                .withCredentials()
                .set('RequestVerificationFormToken', formToken);

            const response = await request.send(sendParams);

            if (typeof response.body === 'string') {
                return Promise.reject(new Error(response.body.replace(/<br \/>/g, ' ').replace(/<br>/g, ' ')));
            }

            const body = response.body as { data?: TData } | undefined;

            if (!body || body.data === undefined) {
                console.error(`Malformed response body from ${url} :`, body);
                return Promise.reject(new Error(`Malformed response body`));
            }

            return Promise.resolve(body.data);
        } catch (_e) {
            // has to cast here so it doesn't loose the cast after the if
            const e = _e as Error | ResponseError;
            if (isResponseError(e)) {
                //somehow this doesn't actually get set
                e.method = method;
                const serverMessage = (e.response as { body?: { message: string } }).body?.message;
                // this by default is a very generic message like "Unauthorized"
                e.message = serverMessage || getGenericMessage(e, method);
            }
            throw e;
        }
    };
};

export const OcApiRequest: ApiRequesterClient = {
    // unfortunately `bind` looses types now because technically it would
    get: buildOcApiRequester(superagent.get.bind(superagent) as typeof superagent.get, 'get'),
    getPost: buildOcApiRequester(superagent.post.bind(superagent) as typeof superagent.post, 'get'),
    post: buildOcApiRequester(superagent.post.bind(superagent) as typeof superagent.post, 'post'),
    put: buildOcApiRequester(superagent.put.bind(superagent) as typeof superagent.put, 'put')
};

export default OcApiRequest;
