import { newGuid } from 'ocfe/src/utils/text';
import { Activity } from '../api';

const elem = document.querySelector('#__hl-activity-trace')!;

type ActivityTemplate = Omit<
    Activity,
    'parentSpanId' | 'sourceName' | 'viewedOn' | 'eventName' | 'ellapsedMilliseconds' | 'contentPath'
>;

const parentActivity: ActivityTemplate = {
    traceId: elem?.getAttribute('traceId') ?? '',
    spanId: elem?.getAttribute('spanId') ?? '',

    tenantCode: elem?.getAttribute('tenantCode') ?? '',
    micrositeKey: elem?.getAttribute('micrositeKey') ?? '',
    navigationKey: elem?.getAttribute('navigationKey') ?? '',
    navigationName: elem?.getAttribute('navigationName') ?? '',

    viewedByContactKey: elem?.getAttribute('viewedByContactKey') ?? '',
    pageOrigin: elem?.getAttribute('pageOrigin') ?? '',
    pagePath: elem?.getAttribute('pagePath') ?? '',
    referrerUrl: elem?.getAttribute('referrerUrl') ?? '',
    userHostAddress: elem?.getAttribute('userHostAddress') ?? '',
    userAgent: elem?.getAttribute('userAgent') ?? ''
};

const FRONT_END_TELEMETRY_SOURCE_NAME = 'browser-telemetry';

export const createActivity = (eventName: string, ellapsedMilliseconds: number): Activity => {
    return {
        ...parentActivity,
        parentSpanId: parentActivity.spanId,
        spanId: newGuid(),
        sourceName: FRONT_END_TELEMETRY_SOURCE_NAME,
        viewedOn: new Date(),

        eventName,
        ellapsedMilliseconds
    };
};
