import superagent from 'superagent';
import { Method } from './OcApiRequest';

const GENERIC_ERROR_MESSAGES: Record<Method, Record<number, string>> = {
    get: {
        401: 'You must be logged in to view this content',
        403: 'You do not have permission to view this content'
    },
    post: {
        401: 'You must be logged in to perform this action',
        403: 'You do not have permission to perform this action'
    },
    put: {
        401: 'You must be logged in to make these changes',
        403: 'You do not have permission to make these changes'
    }
};

const GENERIC_ERROR = 'Server error';

type ResponseError = superagent.ResponseError & {
    response: superagent.Response;
};

export const isResponseError = (error: superagent.ResponseError | Error): error is ResponseError => {
    return 'response' in error;
};

export const handleError = <T>(cb: (e: Error | superagent.ResponseError) => Promise<T>) => {
    return (e: superagent.Response | Error) => {
        if ('error' in e) {
            return cb(e.error);
        } else {
            return cb(e);
        }
    };
};

export const getGenericMessage = (error: superagent.ResponseError, method: Method) => {
    return GENERIC_ERROR_MESSAGES?.[method]?.[error.status] ?? GENERIC_ERROR;
};
