import { addRenderInstance } from './Render/ComponentRenderRegistry';

window.renderReactRoot = (comps, rootId, globalData) => {
    comps.forEach(addRenderInstance);
    void import('./renderApp').then(({ renderApp }) => {
        renderApp(rootId, globalData);
    });
};

window.renderReactDynamic = (comp) => {
    addRenderInstance(comp);
};
