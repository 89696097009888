module.exports = new Promise(resolve => {
    const resolveProxy = () => {
        // this could probably just resolve window[entryScope], but to be safe handling for double init
        const proxy = {
            get: (request) => window['hlContentDesignerRemote'].get(request),
            init: (arg) => {
                try {
                    const initResult = window['hlContentDesignerRemote'].init(arg)
                    console.log('remote container', 'hlContentDesignerRemote', 'is initialized')
                    return initResult;
                } catch(e) {
                    console.warn('remote container', 'hlContentDesignerRemote', 'was already initialized')
                }
            }
        }
        resolve(proxy)
    }

    if (window['hlContentDesignerRemote']) {
        resolveProxy();
    } else {
        const script = document.querySelector('#hlContentDesignerRemote');
        script.onload = () => {
            resolveProxy();
        }
    }
})
;