import { uniqueId } from 'lodash';

type Subscriber = (configs: ReactWidgetConfig[]) => void;
type ReactId = string;

const renderingMap: Record<ReactId, ReactWidgetConfig> = {};
const subscriptions: Record<string, Subscriber> = {};

const notifySubscriber = (sub: Subscriber) => {
    const configs = Object.values(renderingMap);
    sub(configs);
};

const notifyAll = () => {
    const configs = Object.values(renderingMap);
    Object.values(subscriptions).forEach((sub) => sub(configs));
};

export const addRenderInstance = (config: ReactWidgetConfig) => {
    const id = config[1];
    renderingMap[id] = config;

    notifyAll();

    let ob: MutationObserver | null = new MutationObserver(function () {
        if (!document.getElementById(id)) {
            delete renderingMap[id];
        }
        ob?.disconnect();
        ob = null;
    });

    const ele = document.getElementById(id);
    if (ele) {
        ob.observe(ele, { attributes: true });
    }
    const parent = ele?.parentElement;
    if (parent) {
        ob.observe(parent, { childList: true });
    }
};

export const subscribeToWidgetRenders = (fn: Subscriber) => {
    const key = `render-sub-${uniqueId()}`;
    subscriptions[key] = fn;
    notifySubscriber(subscriptions[key]);
    return () => {
        delete subscriptions[key];
    };
};
