// This method takes a string and will compress it using an ellipsis.
// If the text length exceeds the maximum number of characters allowed,
// it will be reduced to maximum characters with an "..." in the middle.
// (ie. file-name...end-of-file.jpg)
export const compressText = (text: string, maxChars = 40) => {
  if (text.length <= maxChars) return text;

  const charsToShow = maxChars - 3;
  const preEllipsisText = Math.ceil(charsToShow / 2);
  const postEllipsisText = Math.floor(charsToShow / 2);

  return `${text.substr(0, preEllipsisText)}...${text.substr(text.length - postEllipsisText)}`;
};

export const shortenText = (text: string, maxChars = 40, addEllipsis = false) => {
  if (text.length <= maxChars) return text;

  const charsToShow = addEllipsis ? maxChars - 1 : maxChars;
  return `${text.substr(0, charsToShow)}${addEllipsis ? '\u2026' : ''}`;
};

export const newGuid = (): string => {
  return crypto.randomUUID();
};
